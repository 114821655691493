import {Component, EventEmitter, Input, Output} from '@angular/core';


import {BrokerContractItem} from "../../services/document-generator.service";
import {MatCheckbox} from "@angular/material/checkbox";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {FormsModule} from "@angular/forms";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatInputModule} from "@angular/material/input";
import {DateTime} from "luxon";

@Component({
  selector: 'app-broker-contract-item',
  templateUrl: './broker-contract-item.component.html',
  styleUrls: ['./broker-contract-item.component.css'],
  imports: [
    MatCheckbox,
    MatFormField,
    FormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatLabel,
  ]
})
export class BrokerContractItemComponent {
  @Input() item: BrokerContractItem;
  @Output()
  selectionChange = new EventEmitter<any>();

  currentDate =  DateTime.now()

  constructor() {
  }
}
