import {inject, NgModule} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterModule, Routes} from '@angular/router';
import {BrokerContractComponent} from './components/broker-contract/broker-contract.component';
import {ProxyComponent} from './components/proxy/proxy.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {DocumentData, DocumentGeneratorService} from "./services/document-generator.service";

const brokerContractResolver: ResolveFn<DocumentData> =
  (route: ActivatedRouteSnapshot) => {
    return inject(DocumentGeneratorService).getBrokerContractData(route.paramMap.get('customerId')!);
  };

const proxyContractResolver: ResolveFn<DocumentData> =
  (route: ActivatedRouteSnapshot) => {
    return inject(DocumentGeneratorService).getProxyContractData(route.paramMap.get('customerId')!);
  };

export const APP_ROUTES: Routes = [
  {
    path: 'customers/:customerId/broker-contract',
    component: BrokerContractComponent,
    resolve: {data: brokerContractResolver},
    title: 'BrokerContract'
  },
  {
    path: 'customers/:customerId/proxy',
    component: ProxyComponent,
    resolve: {data: proxyContractResolver},
    title: 'Proxy'
  },
  {
    path: '**',
    component: PageNotFoundComponent,
    title: 'PageNotFound'
  }
];
