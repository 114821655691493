const DEV_ISSUER_URL = 'https://dev-sso.taures.de:8443/auth/realms/TauRes-Internal';
const PROD_ISSUER_URL = 'https://sso.taures.de/auth/realms/TauRes-Internal';

const devEnvironment = location.origin.includes('dev-');

export const environment = {
  production: true,
  oauth: {
    issuer: devEnvironment ? DEV_ISSUER_URL : PROD_ISSUER_URL,
    clientId: 'ci_document-generator-frontend',
    scope: 'openid email',
    responseType: 'code',
  },
  googleMapsAPIKey: 'AIzaSyD0ew9LFuWfVCGosKDT0XQIe1twS9B38uM'
};
