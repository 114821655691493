import {Injectable} from "@angular/core";
import {LuxonDateAdapter} from "@angular/material-luxon-adapter";

@Injectable({
  providedIn: 'root'
})
export class CustomLuxonDateAdapter extends LuxonDateAdapter {

  getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
    return ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'];
  }
}
