import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';

import {ProxyContractData} from '../../services/document-generator.service';
import {GeoLocationService} from '../../services/geolocation.service';
import {Title} from '@angular/platform-browser';
import {MatCard, MatCardActions, MatCardTitle} from "@angular/material/card";
import {FormsModule} from "@angular/forms";
import {MatFormField, MatLabel} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDatepickerInput, MatDatepickerModule} from "@angular/material/datepicker";
import {SignatureButtonComponent} from "../signature-button/signature-button.component";
import {DownloadButtonComponent} from "../download-button/download-button.component";
import {DateTime} from "luxon";

@Component({
  selector: 'app-proxy',
  templateUrl: './proxy.component.html',
  styleUrls: ['./proxy.component.css'],
  imports: [
    MatCard,
    MatCardTitle,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInputModule,
    MatDatepickerModule,
    MatCardActions,
    SignatureButtonComponent,
    DownloadButtonComponent,
    MatDatepickerInput
  ]
})
export class ProxyComponent implements OnInit, OnDestroy {
  data: ProxyContractData = {} as any;
  private dataSubscription: Subscription;
  private locationSubscription: Subscription;
  loading: boolean;

  constructor(private route: ActivatedRoute,
              private geoLocationService: GeoLocationService,
              private titleService: Title,
              private changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.dataSubscription = this.route.data.subscribe(({data}) => {
        this.titleService.setTitle(`Vollmacht - ${data.firstName}, ${data.lastName} - TauRes`);
        this.data = data;
      }
    );
    this.locationSubscription = this.geoLocationService.getLocality().subscribe((locality) => {
      this.data.signaturePlace = locality;
      this.data.signatureDate = DateTime.now();
      this.changeDetector.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.dataSubscription.unsubscribe();
    this.locationSubscription.unsubscribe();
  }
}
