import {enableProdMode, importProvidersFrom, inject, LOCALE_ID, provideAppInitializer} from '@angular/core';

import {environment} from './environments/environment';
import {bootstrapApplication} from "@angular/platform-browser";
import {AppComponent} from "./app/app.component";
import {provideRouter} from "@angular/router";
import {OAuthModule} from "angular-oauth2-oidc";
import {APP_ROUTES} from "./app/app-routes";
import {AppInitService} from "./app/services/app-init.service";
import {provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {provideAnimations} from "@angular/platform-browser/animations";
import {LogoutService} from "@taures/angular-commons";
import {OauthLogoutService} from "./app/services/oauth-logout.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {DateAdapter, MatDateFormats} from "@angular/material/core";
import {provideLuxonDateAdapter} from "@angular/material-luxon-adapter";
import {MAT_DIALOG_DEFAULT_OPTIONS} from "@angular/material/dialog";
import {CustomLuxonDateAdapter} from "./app/services/custom-luxon-date-adapter";

if (environment.production) {
  enableProdMode();
}

// See the Luxon docs for the meaning of these formats:
// https://moment.github.io/luxon/#/formatting
export const APP_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: ['dd.MM.yyyy', 'D']
  },
  display: {
    dateInput: 'dd.MM.yyyy',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'DDD',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

bootstrapApplication(AppComponent, {
  providers: [
    { provide: LogoutService, useClass: OauthLogoutService },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill'
      }
    },
    {
      provide: LOCALE_ID,
      useValue: 'de-DE'
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { disableClose: true, hasBackdrop: true }
    },
    provideRouter(APP_ROUTES),
    provideLuxonDateAdapter(APP_DATE_FORMAT),
    {provide: DateAdapter, useClass: CustomLuxonDateAdapter},
    importProvidersFrom(OAuthModule.forRoot({resourceServer: {sendAccessToken: true}})),
    provideAppInitializer(() => inject(AppInitService).init()),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch(err => console.error(err));
