@use '@angular/material' as mat;
@use '@taures/angular-commons/taures.theme' as theme;

main {
  min-height: 100vh;
  padding: 80px 1rem 3rem 2rem;
  background: mat.m2-get-color-from-palette(theme.$taures-neutrals, 50);
  font-size: 16px;
}

.content {
  max-width: 960px;
}

@media (min-width: 1920px) {
  .content {
    max-width: 50%;
  }
}
