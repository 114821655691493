@if (isUserInSignatureBeta) {
  <button mat-raised-button color="primary" type="button" (click)="sendToSignature()" [disabled]="disabled || loading">
    F&uuml;r Unterschrift bereitstellen
    @if (!loading) {
      <mat-icon iconPositionEnd svgIcon="sign"></mat-icon>
    } @else {
      <mat-progress-spinner class="inline-block vertical-align-middle ml-2" mode="indeterminate" diameter="24" strokeWidth="2"></mat-progress-spinner>
    }
  </button>
}
