import {ChangeDetectionStrategy, Component, OnInit, ViewEncapsulation} from '@angular/core';
import {
  KeycloakTokenService,
  ToastComponent,
  ToastService,
  ToolbarComponent
} from '@taures/angular-commons';
import {RouterOutlet} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    ToastComponent,
    ToolbarComponent,
    RouterOutlet
  ],
})
export class AppComponent{
  username = this.token.name;
  constructor(private token: KeycloakTokenService) {
  }
}
