import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {DocumentData, DocumentGeneratorService} from '../../services/document-generator.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {MatButton} from "@angular/material/button";
import {MatProgressSpinner} from "@angular/material/progress-spinner";

@Component({
  selector: 'app-download-button',
  templateUrl: './download-button.component.html',
  styleUrls: ['./download-button.component.scss'],
  imports: [
    MatButton,
    MatProgressSpinner
  ]
})
export class DownloadButtonComponent implements OnDestroy {

  @Input()
  loading = false;

  @Output()
  loadingChange = new EventEmitter<boolean>();

  @Input()
  disabled = false;

  @Input()
  data: DocumentData;

  @Input()
  type = 'proxy';

  @ViewChild('downloadLink', {static: true})
  downloadLink: ElementRef;

  private destroy = new Subject<void>();
  private url: string;

  constructor(private readonly documentGeneratorService: DocumentGeneratorService,
              private changeDetectorRef: ChangeDetectorRef) {
  }


  download(): void {
    this.loadingChange.next(true)
    this.documentGeneratorService.downloadDocument(this.type, this.data)
      .pipe(takeUntil(this.destroy))
      .subscribe(file => {
        if (file) {
          this.revoke();
          this.url = URL.createObjectURL(file);
          const anchor = this.downloadLink.nativeElement as HTMLAnchorElement;
          anchor.setAttribute('href', this.url);
          anchor.setAttribute('download', file.name);
          anchor.click();
        }
        this.loadingChange.next(false)
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void {
    this.destroy.next();
    this.destroy.complete();
    this.revoke();
  }

  private revoke(): void {
    if (this.url) {
      URL.revokeObjectURL(this.url);
    }
  }
}
